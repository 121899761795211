<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="loadeventos"
            :headers="headers"
            :actions="actions"
            :permission="'list_evento'"
            :sort-by="['codigo']"
            :sort-desc="[false]"
            :slots="[
              'item.status',
              'item.codigo',
              'item.descricao',
              'item.tipo_evento',
              'item.tipo_calculo',
              'item.tipo_variacao',
              'item.tipo_vigencia',
              'item.tipo_lancamento',
              'item.valor_evento'
            ]"
          >
            <template
              v-if="hasPermission('inserir_evento')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-row>
                  <v-col>
                    <v-menu
                      open-on-hover
                      bottom
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="text-none text-white"
                          color="primary"
                          v-bind="attrs"
                          rounded
                          block
                          v-on="on"
                        >
                          <v-icon
                            dark
                            left
                          >
                            mdi-file-move
                          </v-icon>
                          Exportar
                        </v-btn>
                      </template>
                      <v-list class="ma-0 pa-0">
                        <v-list-item
                          :key="1"
                          :class="{active: true}"
                          @click="exportarExcel"
                        >
                          <v-list-item-title
                            class="mx-2 px-0"
                          >
                            <v-icon
                              color="green"
                              class="mx-2 px-0"
                            >
                              mdi-file-excel
                            </v-icon>
                            Exportar XLSX
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :key="2"
                          :class="{active: true}"
                          @click="exportarPdf"
                        >
                          <v-list-item-title class="mx-2 px-0">
                            <v-icon
                              color="red"
                              class="mx-2 px-0"
                            >
                              mdi-file-pdf
                            </v-icon>
                            Exportar PDF
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="px-1 py-3">
                <v-btn
                  class="text-none text-white"
                  block
                  rounded
                  color="primary"
                  title="Cadastrar novo evento"
                  @click="$router.push({ path: 'eventos/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template
              v-if="hasPermission('ativar_evento')"
              v-slot:[`item.status`]="{ item }"
            >
              <v-checkbox
                v-if="item.classe === 'S'"
                v-model="item.status"
                class="mt-0"
                color="success"
                :readonly="!hasPermission('ativar_evento')"
                :loading="false"
                hint="Ativar/Desativar evento!"
                hide-details
                @change="TOGGLE_EVENTO_STATUS(item)"
              />
            </template>
            <template v-slot:[`item.codigo`]="{ item }">
              <span v-if="item.classe === 'S'">
                {{ item.codigo }}
              </span>
              <span
                v-else
                style="font-weight: bold;"
              >
                {{ item.codigo }}
              </span>
            </template>
            <template v-slot:[`item.descricao`]="{ item }">
              <span
                v-if="item.classe === 'S'"
                style="padding-left: 20px;"
              >
                {{ item.descricao }}
              </span>
              <span
                v-else
                style="font-weight: bold;"
              >
                {{ item.descricao }}
              </span>
            </template>
            <template v-slot:[`item.tipo_evento`]="{ item }">
              <span>{{ item.classe === 'S' ? item.tipo_evento === 0 ? 'Crédito' : item.tipo_evento === 1 ? 'Débito' : '' : '' }}</span>
            </template>
            <template v-slot:[`item.tipo_lancamento`]="{ item }">
              <span>{{ item.classe === 'S' ? item.tipo_lancamento === 0 ? 'Autómatico' : item.tipo_lancamento === 1 ? 'Manual' : '' : '' }}</span>
            </template>
            <template v-slot:[`item.tipo_variacao`]="{ item }">
              <span>{{ item.classe === 'S' ? item.tipo_calculo !== 2 && item.tipo_variacao === 0 ? 'Fixo no evento' : item.tipo_calculo !== 2 && item.tipo_variacao === 1 ? 'Variável por Pessoa' : 'Variável por Lançamento' : '' }}</span>
            </template>
            <template v-slot:[`item.tipo_vigencia`]="{ item }">
              <span>{{ item.classe === 'S' ? item.tipo_vigencia === 0 ? 'Nenhuma' : item.tipo_vigencia === 1 ? 'Período' : 'Meses avulsos' : '' }}</span>
            </template>
            <template v-slot:[`item.tipo_calculo`]="{ item }">
              <span>{{ item.classe === 'S' ? item.tipo_calculo === 0 ? 'Percentual' : item.tipo_calculo === 1 ? 'Valor' : 'Transferência' : '' }}</span>
            </template>
            <template v-slot:[`item.valor_evento`]="{ item }">
              <span v-if="item.classe === 'S' && item.valor_evento > 0">
                {{ `${currencyFormatter(parseFloat(item.valor_evento), 2)}` }}
              </span>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import eventosStore, { BOOTSTRAP, INATIVAR_EVENTO, TOGGLE_EVENTO_STATUS } from '@/store/modules/eventos'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import jsPDF from 'jspdf'
  import XLSX from 'xlsx'
  export default {
    data () {
      return {
        currencyFormatter,
        actions: [
          {
            title: 'Editar',
            color: 'info darken-3',
            click: item => this.getevento(item),
            icon: 'mdi-pencil',
            permission: 'editar_evento',
          },
          {
            title: 'Excluir',
            color: 'red darken-3',
            click: item => this.INATIVAR_EVENTO(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_evento',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, width: '40px' },
          { align: 'center', sortable: false, groupable: false, text: 'Status', value: 'status', width: '40px', floatingfilter: true, report: true },
          { align: 'center', sortable: true, groupable: false, text: 'Código', value: 'codigo', width: '10px', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Descrição', value: 'descricao', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de evento', value: 'tipo_evento', width: 'auto', floatingfilter: false, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de lançamento', value: 'tipo_lancamento', width: 'auto', floatingfilter: false, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de variação', value: 'tipo_variacao', width: 'auto', floatingfilter: false, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de vigência', value: 'tipo_vigencia', width: 'auto', floatingfilter: false, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de cálculo', value: 'tipo_calculo', width: 'auto', floatingfilter: false, report: true },
          { align: 'end', sortable: false, groupable: false, text: 'Valor do evento', value: 'valor_evento', width: 'auto', floatingfilter: false, report: true },
        ],
      }
    },
    computed: {
      ...mapState('eventos', ['loading', 'loadeventos']),
      ...mapGetters(['hasPermission']),
    },
    async created () {
      if (!this.$store.hasModule('eventos')) { this.$store.registerModule('eventos', eventosStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('eventos', [BOOTSTRAP, INATIVAR_EVENTO, TOGGLE_EVENTO_STATUS]),
      getevento ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/eventos/alterar`,
          query: { id },
        })
      },
      exportarExcel () {
        const data = this.loadeventos
        try {
          var table = [
            ['Relatorio Eventos de Cálculo'],
            [''],
            ['Status', 'Código', 'Descrição', 'Tipo de evento', 'Tipo de lançamento', 'Tipo de variação', 'Tipo de vigência', 'Tipo de cálculo', 'Valor do evento'],
          ]
          data.forEach(item => {
            table.push([
              item.status,
              item.codigo,
              item.descricao,
              item.tipo_evento,
              item.tipo_lancamento,
              item.tipo_variacao,
              item.tipo_vigencia,
              item.tipo_calculo,
              parseFloat(item.valor_evento),
            ])
          })
          var ws = XLSX.utils.aoa_to_sheet(table)

          const colWidths = table[3].map((_, colIndex) => table.reduce((maxWidth, currentRow) => {
            const cellValue = currentRow[colIndex]
            const cellWidth = (typeof cellValue === 'string' ? cellValue.length : 0)
            return Math.max(maxWidth, cellWidth)
          }, 0))

          ws['!cols'] = colWidths.map(width => ({ wch: width + 2 }))

          var wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Relatorio Eventos de Cálculo')

          XLSX.writeFile(wb, 'Relatorio Eventos de Cálculo.xlsx')
        } catch (e) {
          console.log(e)
        }
      },
      exportarPdf () {
        const spaceLine = 7
        const fontSize = spaceLine + 1
        const fontTitleSize = fontSize * 2
        const margin = { left: 10, right: 10, bottom: 10, top: 28 }

        function addHeader () {
          doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, fontSize * 1.8, fontSize * 1.8)
          doc.setFontSize(fontTitleSize)
          doc.text('Relatorio Eventos de Cálculo', docWidth / 2, 15, { align: 'center' })
          doc.setFontSize(fontSize)
          doc.text(dateNow, docWidth - margin.right, (20), { align: 'right' })
          doc.line(5, (24), docWidth - 5, (24))
        }

        function addFooter (pageNumber) {
          doc.setFontSize(fontSize - 1)
          doc.text(dateNow, margin.left - 5, docHeight - margin.bottom)
          doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }

        var doc = new jsPDF({
          orientation: 'landscape',
        })
        var totalPages = '{total_pages_count_string}'
        const dateNow = (new Date()).toLocaleString()
        const docWidth = doc.internal.pageSize.width
        const docHeight = doc.internal.pageSize.height
        var cols = [
          { title: 'Código', dataKey: 'codigo' },
          { title: 'Status', dataKey: 'status' },
          { title: 'Descrição', dataKey: 'descricao' },
          { title: 'Tipo de evento', dataKey: 'tipo_evento' },
          { title: 'Tipo de lançamento', dataKey: 'tipo_lancamento' },
          { title: 'Tipo de variação', dataKey: 'tipo_variacao' },
          { title: 'Tipo de vigência', dataKey: 'tipo_vigencia' },
          { title: 'Tipo de cálculo', dataKey: 'tipo_calculo' },
          { title: 'Valor do evento', dataKey: 'valor_evento' },
        ]
        doc.autoTable({
          theme: 'plain',
          headStyles: {
            textColor: '#FFFFFF',
            fillColor: '#1E88E5',
          },
          columns: cols,
          columnStyles: {
            codigo: { halign: 'center' },
            valor_evento: { halign: 'right' },
          },
          body: this.loadeventos,
          startY: 27,
          pageBreak: 'auto',
          margin: { left: 5, top: margin.top, bottom: (margin.bottom + 5), right: 5 },
          didParseCell: function (data) {
            if (data.section === 'body') {
              if (data.column.dataKey === 'status') {
                if (parseInt(data.cell.text) === 1) {
                  data.cell.text = 'Ativo'
                }
                if (parseInt(data.cell.text) === 0) {
                  data.cell.text = 'Inativo'
                }
              }
              if (data.column.dataKey === 'tipo_evento') {
                if (parseInt(data.cell.text) === 0) {
                  data.cell.text = 'Crédito'
                }
                if (parseInt(data.cell.text) === 1) {
                  data.cell.text = 'Débito'
                }
              }
              if (data.column.dataKey === 'tipo_variacao') {
                if (parseInt(data.cell.text) === 0) {
                  data.cell.text = 'Fixo no evento'
                }
                if (parseInt(data.cell.text) === 1) {
                  data.cell.text = 'Variável por Pessoa'
                }
                if (parseInt(data.cell.text) === 2) {
                  data.cell.text = 'Variável por Lançamento'
                }
              }
              if (data.column.dataKey === 'tipo_vigencia') {
                if (parseInt(data.cell.text) === 0) {
                  data.cell.text = 'Nenhuma'
                }
                if (parseInt(data.cell.text) === 1) {
                  data.cell.text = 'Período'
                }
                if (parseInt(data.cell.text) === 2) {
                  data.cell.text = 'Meses avulsos'
                }
              }
              if (data.column.dataKey === 'tipo_calculo') {
                if (parseInt(data.cell.text) === 0) {
                  data.cell.text = 'Percentual'
                }
                if (parseInt(data.cell.text) === 1) {
                  data.cell.text = 'Valor'
                }
                if (parseInt(data.cell.text) === 2) {
                  data.cell.text = 'Transferência'
                }
              }
              if (data.column.dataKey === 'valor_evento') {
                // data.cell.styles.halign = 'right'
                if (data.cell.text[0] !== '') {
                  if (data.row.cells.tipo_calculo.text[0] === 'Percentual') {
                    data.cell.text[0] = currencyFormatter(parseFloat(data.cell.text), 2) + ' %'
                  }
                  if (data.row.cells.tipo_calculo.text[0] === 'Valor') {
                    data.cell.text = 'R$ ' + currencyFormatter(parseFloat(data.cell.text), 2)
                  }
                  if (data.row.cells.tipo_calculo.text[0] === 'Transferência') {
                    data.cell.text = currencyFormatter(parseFloat(data.cell.text), 2)
                  }
                }
              }
            }
          },
          didDrawPage: (data) => {
            addHeader()
            addFooter(data.pageNumber)
          },
        })
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPages)
        }

        doc.save('Relatorio Eventos de Cálculo' + '.pdf')
      },
    },
  }
</script>
<style lang="scss" scoped>

.v-menu__content {
  border-radius: 16px !important;
}
</style>
